




































import AppState from "@/store/modules/app-module";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import StatusChangeMetadataConfig from "@/components/configuration/status-change-metadata-config.vue"
import StatusConfiguration from "@/components/configuration/status-configuration.vue"
import config from '@/config';

import Card from "@/components/material/Card.vue";

const appState = getModule(AppState);
@Component({
  components: {
    Card,
    StatusChangeMetadataConfig,
    StatusConfiguration
  }
})
export default class ConfigurationView extends Vue {

  configurationComponents = [
    { name: "Status Metadata", id: "status", component: StatusConfiguration },
    { name: "Status Change Metadata", id: "statuschange", component: StatusChangeMetadataConfig },
  ]

  selected: string | null = null;

  tab = 1;

  get selectedComponent() {
    return this.configurationComponents.find(x=>x.id ==this.selected)?.component
  }

  setSelected(value: string){
    this.selected = value;
  }

  navigateToHazcheckDetect(){
    window.location.href = this.HazcheckDetectLink;
  }

  get color() {
    return appState.apiFault ? "error" : "primary";
  }

  get HazcheckDetectLink(){
    return `${config.hazcheckDetectUrl}/#/Configuration`
  }
}
