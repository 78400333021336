


































































import { Component, Vue, Inject} from "vue-property-decorator";
import StatusConfigDialog from "@/components/configuration/status-config-dialog.vue";

import Card from "@/components/material/Card.vue";
import EcSnackBar from "@/components/common/ec-snackbar.vue";

import ConfigurationService, { StatusChangeMetadataConfigModel, AllowCaseDetail } from "@/services/configuration-service";
import StatusConfig from "@/components/configuration/status-config-table.vue";
import { getModule } from "vuex-module-decorators";
import AppState from "@/store/modules/app-module";
import * as snackbarMessaging from "@/helpers/snackbarMessaging";
import { SnackbarOptions } from "@/models/form";

const appState = getModule(AppState);

@Component({
  components: {
    Card,
    EcSnackBar,
    StatusConfig,
    StatusConfigDialog,
  },
})
export default class StatusChangeMetadataConfig extends Vue {

@Inject() ConfigurationService!: ConfigurationService;

configModel: StatusChangeMetadataConfigModel = { AllowCaseDetails : []}; 

snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();

optionToAdd = { source: "", key: "" } as AllowCaseDetail;

sourceOptions = [
    "CorrelationData", 
    "BookingData", 
    "Hit", 
    "Metadata"
]

editErrors: any[] = []
errors: string[] = [];

AlreadyExistsError = "Already exists!"

addToOptions() {
    this.errors = [];

    if(this.isEmptyOrSpaces(this.optionToAdd.source)){
        this.errors.push("Source can not be blank")
        return;
    } 

    if(this.isEmptyOrSpaces(this.optionToAdd.key)){
        this.errors.push("Key can not be blank")
        return;
    } 

    if(!this.configModel.AllowCaseDetails)
    {
       this.configModel.AllowCaseDetails = [this.optionToAdd];
       this.optionToAdd = { source: "", key: ""} as AllowCaseDetail;
       return;
    }
        
    if(this.optionsExists(this.optionToAdd.source, this.optionToAdd.key)) 
    {
        this.errors.push(this.AlreadyExistsError)
        return;
    }

    this.configModel?.AllowCaseDetails.push(this.optionToAdd)
    this.optionToAdd = { source: "", key: ""} as AllowCaseDetail;
}

editOption(newValue: string, index: number, fieldSelected: string){   
    const currentValue = this.configModel.AllowCaseDetails[index] as any

    if(fieldSelected == "key" && this.optionsExists(currentValue.source, newValue) 
        || fieldSelected == "source" && this.optionsExists(newValue, currentValue.key))
    {
        this.editErrors.push({index, "message": this.AlreadyExistsError})
        return;
    }
    else
    {
        this.editErrors = [];
        currentValue[fieldSelected] = newValue;
    }
}

editError(index: number){
    return this.editErrors.filter(x => x.index == index)
}

optionsExists(source: string, key: string){
    return this.configModel?.AllowCaseDetails.filter(option => 
        option.key.localeCompare(key, undefined, { sensitivity: 'accent'}) === 0  
        &&  option.source.localeCompare(source, undefined, { sensitivity: 'accent'}) === 0    
    ).length > 0
}

get isError() {
    return appState.apiError.message && appState.apiError.message.length > 0
}

isEmptyOrSpaces(str: string){
    return str.trim() === "";
}

removeFromOptions(config: AllowCaseDetail) {
    if(this.configModel !== null){
        this.configModel.AllowCaseDetails = this.configModel?.AllowCaseDetails
            .filter(x=> x.key !== config.key &&  x.source !== config.source)            
        return this.configModel?.AllowCaseDetails;  
    }   
}

async writeToDatabase() {
    this.errors = [];
    try{
        await this.ConfigurationService.saveStatusChangeMetadata(this.configModel)
        snackbarMessaging.setSuccessMessage("Configuration Saved", { timeout: 2000 } as SnackbarOptions)
    }catch (error) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as any);
    }
   
}

async mounted() {
    appState.resetApiError();
    const configModel = await this.ConfigurationService.getStatusChangeMetadataConfig();
    if(this.configModel !== null){
        this.configModel.AllowCaseDetails = configModel.AllowCaseDetails;
    }   
  }

}
