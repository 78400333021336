
















































































































































import { Component, PropSync, Prop } from "vue-property-decorator";
import Vue from "vue";
import {
  StatusMetadata,
  MetadataValue,
  OrPredicateMetadataCondition
} from "@/services/configuration-service";
import ConditionalRequirementsEditor from "./conditional-requirements-editor.vue";
import { DROPDOWN, METADATA_INPUT_TYPES } from "./configuration-constants";
import StatusConfigErrors from "./status-config-errors.vue";

@Component({
  components: {
    ConditionalRequirementsEditor,
    StatusConfigErrors
  }
})
export default class StatusConfigRow extends Vue {
  @PropSync("metadata", { default: null }) syncMetadata!: StatusMetadata;

  @Prop({ default: "first", type: Boolean }) first!: boolean;
  @Prop({ default: "last", type: Boolean }) last!: boolean;
  @Prop({ required: true, type: Array }) allMetadata!: StatusMetadata[];
  @Prop({ default: null }) validateConditionalRequirements!: (
    allMetadata: StatusMetadata[],
    conditionsToValidate: OrPredicateMetadataCondition[]
  ) => string[];

  errors: string[] = [];
  dropdownValues: Array<{ key: string; value: string }> = [];
  optionsDialogOpen = false;
  setConditionalRequirementParametersDialog = false;
  requiredConditionsCopy: OrPredicateMetadataCondition[] = [];

  get availableTypes() {
    return METADATA_INPUT_TYPES;
  }

  get typeOfDataSelectedIsDropdown() {
    return this.syncMetadata.type === DROPDOWN;
  }

  get requiredLabel() {
    if (this.syncMetadata.isRequired === true) return "Always";
    if (this.syncMetadata.isRequired === null) return "Conditionally";
    if (this.syncMetadata.isRequired === false) return "Never";
  }

  get localIsRequired() {
    if (this.syncMetadata.isRequired === false) return 0;
    if (this.syncMetadata.isRequired === null) return 1;
    return 2;
  }
  set localIsRequired(value: number) {
    if (value === 0) this.syncMetadata.isRequired = false;
    if (value === 1) this.syncMetadata.isRequired = null;
    if (value === 2) this.syncMetadata.isRequired = true;
  }

  copyRequiredConditions(conditions: OrPredicateMetadataCondition[]): void {
    this.requiredConditionsCopy = JSON.parse(JSON.stringify(conditions));
  }

  ValidateAndSetConditionalRequirements(): void {
    this.errors = this.validateConditionalRequirements(
      this.allMetadata,
      this.requiredConditionsCopy
    );

    if (this.errors.length == 0) {
      this.syncMetadata.requiredConditions = this.requiredConditionsCopy;
      this.ResetConditionsAndCloseDialog();
    }
    //pop up errors list ?
  }

  ResetConditionsAndCloseDialog() {
    this.setConditionalRequirementParametersDialog = false;
    this.requiredConditionsCopy = [];
  }

  addMetadataOption() {
    const newValue: MetadataValue = {
      displayName: "",
      value: ""
    };
    this.syncMetadata.values.push(newValue);
  }

  deleteMetadataValue(model: MetadataValue) {
    const index = this.syncMetadata.values.indexOf(model);
    this.syncMetadata.values.splice(index, 1);
  }
}
