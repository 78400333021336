



































































































import { Component, PropSync, Prop, Vue } from "vue-property-decorator";
import {
  StatusMetadata,
  OrPredicateMetadataCondition,
  Condition,
  MetadataValue
} from "@/services/configuration-service";
import {
  CONDITION_TYPES,
  DROPDOWN,
  EQUALS,
  FREETEXT,
  METADATA_INPUT_TYPES,
  NOT_NULL
} from "./configuration-constants";

@Component
export default class ConditionalRequirementsEditor extends Vue {
  @PropSync("requiredConditions", { type: Array })
  requiredConditionsSync!: OrPredicateMetadataCondition[];
  @Prop({ required: true, type: Array }) allMetadata!: StatusMetadata[];
  @Prop({ required: true, type: Object }) thisMetadata!: StatusMetadata;
  NOT_NULL = NOT_NULL;

  addOuterCondition(currentMetadata: StatusMetadata) {
    const defaultedAndCondition: Condition = {
      path: this.getOtherMetadataName(currentMetadata),
      predicate: EQUALS,
      value: ""
    };
    this.requiredConditionsSync.push({ andPredicates: [defaultedAndCondition] });
  }

  deleteOuterCondition(index: number) {
    this.requiredConditionsSync.splice(index, 1);
  }

  addInnerCondition(orCondition: OrPredicateMetadataCondition, currentMetadata: StatusMetadata) {
    orCondition.andPredicates.push();
    const defaultedAndCondition: Condition = {
      path: this.getOtherMetadataName(currentMetadata),
      predicate: EQUALS,
      value: ""
    };
    orCondition.andPredicates.push(defaultedAndCondition);
  }

  private getOtherMetadataName(currentMetadata: StatusMetadata) {
    return this.allMetadata.filter(x => x.name != currentMetadata.name)[0]?.name ?? "";
  }

  deleteInnerCondition(index: number, orCondition: OrPredicateMetadataCondition) {
    orCondition.andPredicates.splice(index, 1);
  }

  getAllOtherFieldsDropdownOptions() {
    return this.allMetadata.filter(x => x.name !== this.thisMetadata.name).map(x => x.name);
  }

  SelectedFieldValues(selectedFieldName: string) {
    return this.allMetadata.find(x => x.name === selectedFieldName)?.values;
  }

  get conditionTypes() {
    return CONDITION_TYPES;
  }

  get fieldTypes() {
    return METADATA_INPUT_TYPES;
  }

  isOtherFieldFreetext(path: string) {
    const otherField = this.allMetadata.find(x => x.name === path);

    if (otherField) {
      return otherField.type === FREETEXT;
    }
  }

  isOtherFieldDropdown(path: string) {
    const otherField = this.allMetadata.find(x => x.name === path);

    if (otherField) {
      return otherField.type === DROPDOWN;
    }
  }
}
