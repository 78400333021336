





































import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import {
  OrPredicateMetadataCondition,
  SingleStatusConfig,
  StatusMetadata
} from "@/services/configuration-service";
import StatusConfigRow from "@/components/configuration/status-config-row.vue";

@Component({
  components: {
    StatusConfigRow
  }
})
export default class StatusConfig extends Vue {
  @Prop({ default: null }) validateConditionalRequirements!: (
    allMetadata: StatusMetadata[],
    conditionsToValidate: OrPredicateMetadataCondition[]
  ) => string[];
  @Prop({ default: null }) statusData!: SingleStatusConfig;

  dropdownValues: Array<{ key: string; value: string }> = [];

  AvailableTypes() {
    return [{ name: "dropdown" }, { name: "freetext" }];
  }

  mounted() {
    this.updateDropdownValues();
  }

  updateDropdownValues() {
    this.dropdownValues = [];

    this.statusData.metadata.forEach(metadata => {
      let resultString = "";
      metadata.values.forEach(value => {
        resultString = resultString + ", " + value.value;
      });
      this.dropdownValues.push({ key: metadata.name, value: resultString });
    });
  }

  shoudlDisplayCommaSeparatedList(metadata: StatusMetadata) {
    return metadata.type === "dropdown";
  }

  addNewRow() {
    const statusMetadata: StatusMetadata = {
      type: "freetext",
      name: "",
      values: [],
      isRequired: false,
      requiredConditions: []
    };
    this.statusData.metadata.push(statusMetadata);
  }

  sortup(model: StatusMetadata) {
    const index = this.statusData.metadata.indexOf(model);
    this.statusData.metadata.splice(index, 1);
    this.statusData.metadata.splice(index - 1, 0, model);
  }

  sortdown(model: StatusMetadata) {
    const index = this.statusData.metadata.indexOf(model);
    this.statusData.metadata.splice(index, 1);
    this.statusData.metadata.splice(index + 1, 0, model);
  }

  deleterow(model: StatusMetadata) {
    const index = this.statusData.metadata.indexOf(model);
    this.statusData.metadata.splice(index, 1);
  }
}
